import {Modal} from "bootstrap";
import EtSlider from "@/ts/components/EtSlider";
import Warenkorb from "@/ts/components/Warenkorb";
import EtConsole from "@/ts/components/EtConsole";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";
import EtPersonalJson from "@/ts/components/EtPersonalJson";
import CookieControlNotice from "@/ts/components/CookieControlNotice";
import FrontendVueHandler from "../components/Handlers/FrontendVueHandler";
import PiniaStoreManager from "@/ts/components/PiniaStoreManager";
import EtailerFrontend from "@/ts/components/EtailerFrontend";
import Merkliste from "@/ts/components/Merkliste";
import WarenkorbPreview from "@/ts/components/WarenkorbPreview";
import FormValidation from "@/ts/components/FormValidation";
import LieferanschriftSelector from "@/ts/components/LieferanschriftSelector";
import MobileMenu from "@/ts/components/MobileMenu";
import DesktopMenu from "@/ts/components/DesktopMenu";
import SuperHeader from "@/ts/components/SuperHeader";
import EtFormAutoComplete from "@/ts/components/EtFormAutoComplete";
import EtVideoLoader from "@/ts/components/EtVideoLoader";
import {initAllElementArtikel} from "@/ts/elements/artikel/element_artikel";
import {updateInputField} from "@/ts/components/artikelFunctions";

export default async function (vwtype: string, vwname: string): Promise<void> {

    // EtConsole implements browser.Console
    const etConsole: EtConsole = new EtConsole(window.etData.environmentName === "dev", []);
    FrontendHandler.setPublicVars({etConsole: etConsole});

    // initializes ts pages
    const etailerFrontend: EtailerFrontend = new EtailerFrontend();

    // public page modal
    FrontendHandler.setPublicVars({
        pageModalApi: new Modal(document.getElementById("page_modal") || '#page_modal', {backdrop: true, keyboard: true})
    });

    // init mobile menu
    const mobileMenuElement = document.getElementById("mobileNavigation");
    if (mobileMenuElement && mobileMenuElement instanceof HTMLElement) {
        // delayed init
        mobileMenuElement.addEventListener("show.bs.offcanvas", () => {
            new MobileMenu(mobileMenuElement, window.etData.openMenuIds || []);
        });
    }

    // init desktop menu
    new DesktopMenu(".layout_header__navigation", window.etData.openMenuIds || []);

    // init element_superheader.tpl
    const superHeader: HTMLElement | null = document.getElementById("superheader");
    if (superHeader && etailerFrontend) {
        new SuperHeader(superHeader, etailerFrontend);
    }

    // SwiperJs
    // TODO: erst bei window.load initialisieren. Das kann aber nicht hier registriert werden, weil lokal das Load-Event schon durch ist, wenn die Funktion hier aufgerufen wird.
    const firstSwiperElement = document.querySelector(".swiper");
    if (firstSwiperElement) {
        new EtSlider(true);
    }

    // CookieControlNotice popup
    const cookieControlElement = document.getElementById("cookiecontrol_notice");
    if (cookieControlElement) {
        new CookieControlNotice(cookieControlElement as HTMLDivElement);
    }

    // PiniaStoreManager (manages Pinia stores)
    const piniaStoreManager: PiniaStoreManager = new PiniaStoreManager();
    FrontendHandler.setPublicVars({piniaStoreManager: piniaStoreManager});
    piniaStoreManager.getPersonalJsonData();

    // Variable Collector (imports Smarty variables and passes them into Pinia stores)
    const warenkorb: Warenkorb = new Warenkorb(EtPersonalJson.getInstance(), piniaStoreManager);
    FrontendHandler.setPublicVars({warenkorb: warenkorb});

    document.querySelectorAll("[data-et-pj-dom-html=\"cart-dropdown\"]").forEach((cartPreviewElement) => {
        if (cartPreviewElement instanceof HTMLElement) {
            new WarenkorbPreview(cartPreviewElement);
        }
    });

    const merklisteElement = document.querySelector("[data-et-pj-counter-name=\"wishlist\"]");
    const add2WishlistElement = document.querySelector(".artikel_detail__buybox_favourite");
    if (merklisteElement || add2WishlistElement) {
        const merkliste: Merkliste = new Merkliste(piniaStoreManager);
        FrontendHandler.setPublicVars({merkliste: merkliste});
    }

    // adding the quick view feature to element_artikel
    initAllElementArtikel();

    const vueElement = document.querySelector("[id^='etvue_']");
    if (vueElement) FrontendVueHandler.initPageVue();

    // TODO: Auf Adressformulare beschränken! Beim Newsletter-Anmeldeformular im Footer ist das Sinnlos!
    const formElements = document.querySelectorAll("form");
    formElements.forEach((formElement: HTMLFormElement) => {
        new FormValidation(formElement);
        new EtFormAutoComplete(formElement);
        if (FrontendHandler.getPublicVar("_la")) new LieferanschriftSelector(formElement);
    });

    FrontendHandler.initWishlistButtons();

    new EtVideoLoader();

    window.updateInputField = updateInputField;
}
