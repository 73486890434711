/**
 * TypeScript core related to element_artikel.js
 *
 * @see element_artikel.tpl
 * @see _element_artikel.scss
 */

import {Modal} from "bootstrap";
import FrontendHandler from "@/ts/components/Handlers/FrontendHandler";

export function initAllElementArtikel() {
    document.querySelectorAll(".element_artikel").forEach((elementArtikel) => {
        if (elementArtikel instanceof HTMLElement) {
            initOneElementArtikel(elementArtikel)
        }
    });
}

export function initOneElementArtikel(elementArtikel: HTMLElement) {
    if (!(elementArtikel instanceof HTMLElement) || !elementArtikel.classList.contains('element_artikel')) {
        return false;
    }

    if (elementArtikel.dataset.initElementArtikel !== 'done') {
        elementArtikel.dataset.initElementArtikel = 'done';
        setupQuickViewButtonClickEvents(elementArtikel);
    }

    return true;
}

function setupQuickViewButtonClickEvents(elementArtikel: HTMLElement) {
    elementArtikel.querySelectorAll("[data-load-quickview]").forEach((button) => {
        if (!(button instanceof HTMLButtonElement) || button.dataset.initQuikview === 'done') {
            return;
        }
        button.dataset.initQuikview = 'done';
        button.disabled = false;
        button.addEventListener("click", (event) => {
            event.preventDefault();
            event.stopPropagation();
            const artikelId = parseInt(button.getAttribute("data-load-quickview") ?? "0");

            const pageModalApi: Modal = FrontendHandler.getPublicVar<Modal>("pageModalApi");
            if (pageModalApi) {
                pageModalApi.show();
            }

            FrontendHandler.loadTemplate(
                "artikel/artikel_detail/json_artikel_detail_quickview",
                "modal_body",
                undefined,
                true,
                artikelId,
                "artikel",
                "detail"
            ).then(() => {
                FrontendHandler.initWishlistButtons();
            });
        });

    });
}