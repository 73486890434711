import PiniaStoreManager from "@/ts/components/PiniaStoreManager";
import ApiHandler from "@/ts/components/Handlers/ApiHandler";
import {AnyType} from "@/ts/components/Types/AnyType";

export default class Merkliste {
    private piniaStoreManager: PiniaStoreManager;
    private merkliste: { merkzettel: AnyType[] };

    /**
     * Initializes the Merkliste.
     * @param piniaStoreManager
     */
    constructor(piniaStoreManager: PiniaStoreManager) {
        this.piniaStoreManager = piniaStoreManager;

        this.merkliste  = this.piniaStoreManager.getMerklisteStore().getMerkliste;

        // Wenn es keine Merkliste gibt, erstellt einen neuen Merkzettel
        if(this.merkliste.merkzettel && this.merkliste.merkzettel.length === 0){
            this.createNewMerkzettel("Standard");
        }
    }


    /**
     * Adds a Merkzettel to a Merkzettel.
     * @param name
     */
    public async createNewMerkzettel(name: string) {
        await ApiHandler.getJsonResponse({
            service: "createMerkliste",
            mdesc: name
        }).then(() => {
            return Promise.resolve();
        }).catch((e) => {
            return Promise.reject(e);
        });
    }

    public async addArtikel(merklisteId: string, artikelId: string) {
        if (!merklisteId) return Promise.reject("invalid merklisteId");
        if (!artikelId) return Promise.reject("invalid artikelId");
        try {
            await ApiHandler.getJsonResponse(
                {
                    service: "getViewContent",
                    url: `/index.php?ac_type=merkliste&ac_name=add&ac_id=${artikelId}&vw_type=artikel&vw_name=detail&vw_id=${artikelId}&opt=${merklisteId}`
                },
                undefined,
                {cache: "no-cache"}
            )
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }


    public async removeArtikel(merklisteId: number|string): Promise<void | null> {
        if (!merklisteId) return Promise.reject("invalid merklisteId");
        try {
            await ApiHandler.getJsonResponse(
                {
                    service: "getViewContent",
                    url: `/index.php?ac_type=merkliste&ac_name=remove&ac_id=${merklisteId.toString()}&vw_type=merkliste&vw_name=detail&template=merkliste/json_merkliste_empty`,
                },
                undefined,
                {cache: "no-cache"}
            );
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async setMerkzettelName(merkzettelId: number|string, value: string): Promise<void | null> {
        if (!merkzettelId) return Promise.reject("invalid merkzettelId");
        if(value.trim() === "") return Promise.reject("invalid_name");
        try {
            await ApiHandler.getJsonResponse({
                service: "renameMerkliste",
                mid: merkzettelId.toString(),
                mdesc: value,
            });
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Moves an Artikel to another Merkzettel.
     * @param merkzettelId
     * @param artikel_id
     */
    public async moveArtikel(
        artikel_id: number|string,
        merkzettelId: number|string
    ): Promise<void | null> {
        try {
            await ApiHandler.getJsonResponse({
                service: "moveItemToMerkliste",
                mid: merkzettelId.toString(),
                lid: artikel_id.toString(),
            })
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async moveMultipleArtikel(
        old_merkliste_id: number|string,
        new_merkliste_id: number|string,
        artikel_ids: string[],
        shouldDeleteOldMerkliste: boolean
    ): Promise<void> {
        try {
            await Promise.all(artikel_ids.map(async (artikel_id) => {
                await this.moveArtikel(artikel_id, new_merkliste_id);
            }));
            if (shouldDeleteOldMerkliste) await this.removeMerkzettel(old_merkliste_id);
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async removeMultipleArtikel(
        artikel_ids: string[],
    ): Promise<void> {
        try {
            await Promise.all(artikel_ids.map(async (artikel_id) => {
                await this.removeArtikel(artikel_id);
            }));
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * Removes a Merkzettel from the Merkliste.
     * @param merkzettel
     * @param moveToMerkzettel
     */
    public async removeMerkzettel(
        merkzettel: string | number,
        moveToMerkzettel?: string | number
    ): Promise<void | null> {
        try {
            await ApiHandler.getJsonResponse({
                service: "removeMerkliste",
                mid: merkzettel.toString(),
                lid: moveToMerkzettel?.toString() ?? merkzettel.toString(),
            });
            return Promise.resolve();
        } catch (e) {
            return Promise.reject(e);
        }
    }
}