import FunctionHandler from "@/ts/components/Handlers/FunctionHandler";

var scrollY = window.scrollY;

export default function (selector: string) {
    const scrollButton = document.querySelector(selector) as HTMLElement;
    if (scrollButton) {
        handleScroll(scrollButton);
        const throttledHandler = FunctionHandler.throttle(() => { handleScroll(scrollButton) }, 250);
        scrollButton.addEventListener('click', scrollToTop);

        window.addEventListener('scroll', throttledHandler);
    }
}

function handleScroll(buttonElement:HTMLElement) {
    buttonElement.hidden = scrollY < window.scrollY || window.scrollY <= 300;
    scrollY = window.scrollY;
}

function scrollToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}